/* eslint-disable */
import React, { useRef, useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Button from "./../components/Button"
import Footer from "../components/Footer"
import NewsCards from "./../components/Cards/NewsList"
import ResourceCard from "./../components/Cards/ResourceCard"
import { graphql } from "gatsby"
import "fullpage.js/vendors/scrolloverflow"
import ReactFullpage from "@fullpage/react-fullpage"
// import * as ScrollMagic from "scrollmagic"
import { gsap, TweenLite, Expo, Power1, CSSRulePlugin } from "gsap/all"
gsap.registerPlugin(CSSRulePlugin)
import IconDoor from "./../assets/brand/icons_door.svg"
import IconExtinguisher from "./../assets/brand/icons_extinguisher.svg"
import IconSprinkler from "./../assets/brand/icons_sprinkler.svg"
import IconSmokedetector from "./../assets/brand/icons_smokedetector.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import Popup from "./../components/PopUp"
import "../styles/page-home.scss"
import { FormattedMessage } from "react-intl"
import SimpleLocalize from "../../src/SimpleLocalize"

export default ({
  data: {
    resourcesEnglish: { nodes: resourcesEnglish },
    resourcesSpanish: { nodes: resourcesSpanish },
    newsDescendingOrder: { nodes: newsDescendingOrder },
  },
  pageContext,
}) => {
  const isEs = pageContext.language === "es"
  const resources = isEs ? resourcesSpanish : resourcesEnglish
  //onClick for button to play vimeo video in modal
  const [modalShow, setModalShow] = useState(false)
  const modalPopUp = flag => setModalShow(flag)
  const [modalShowSlide2, setModalShowSlide2] = useState(false)
  const modalPopUpSlide2 = flag => setModalShowSlide2(flag)
  const fpEl = useRef(null)
  const SEL = "slider"
  const SECTION_SEL = `.${SEL}`
  const originalColors = ["", "", "", "", "", "", "#ffffff", "#ffffff"]
  const sectionsColor = [...originalColors]
  const anchors = [
    "escape-plan",
    "close-before-you-doze",
    ...(isEs ? [] : ["fire-is-getting-faster"]),
    "make-a-900-difference",
    "take-it-down-a-notch",
    "good-morning-america",
    "cbyd",
    "resources",
  ]
  const skipHero = () => (window.location.href = "#cbyd")
  const nextSlide = () =>
    fpEl.current && fpEl.current.fullpageApi.moveSectionDown()

  const transition = (origin, destination, direction) => {
    let index = anchors.indexOf(origin.anchor)
    if ("down" === direction) {
      TweenLite.to(`.hero-bg-slide-${index + 1}`, 0.6, {
        autoAlpha: 0,
        ease: Expo.easeOut,
      })
      TweenLite.fromTo(
        `.hero-bg-slide-${index + 2}`,
        0.6,
        { display: "block", autoAlpha: 0 },
        { autoAlpha: 1, ease: Expo.easeOut }
      )
      TweenLite.fromTo(
        `.img-slide-${index + 2}`,
        0.7,
        { y: 800 },
        { delay: 0.1, y: 0, ease: Power1.easeOut }
      )

      // second to last
      if (anchors.length - 1 === index) {
        TweenLite.to("#fp-nav", 0.2, { autoAlpha: 0 })
        // TweenLite.to('.secondary-nav', .2, { delay:.4, background: 'rgba(255, 255, 255, 1)' });
      }
    } else if ("up" === direction) {
      TweenLite.to(`.hero-bg-slide-${index + 2}`, 0.6, {
        autoAlpha: 0,
        ease: Expo.easeOut,
      })
      TweenLite.to(`.hero-bg-slide-${index + 1}`, 0.6, {
        autoAlpha: 1,
        ease: Expo.easeOut,
      })

      // last
      if (anchors.length === index) {
        TweenLite.to("#fp-nav", 0.2, { autoAlpha: 1 })
        // TweenLite.to('.secondary-nav', .1, { background: 'rgba(255, 255, 255, 0)' });
        // TweenLite.to('.secondary-nav .menu', .1, { color:'#ffffff' });
        // TweenLite.to('.hero-nav-items', .2, { autoAlpha:1 });
      }
    }
  }

  return (
    <SimpleLocalize {...pageContext}>
      <Layout
        hideFooter={true}
        pageInfo={{ pageName: "home" }}
        fluid={true}
        language={pageContext.language}
      >
        <SEO title="Home" keywords={[`close your door`]} />
        <div className="hero-bg-wrapper">
          <div className="hero-bg-slide-7"></div>
          <div className="hero-bg-slide-6"></div>
          <div className="hero-bg-slide-5"></div>
          <div className="hero-bg-slide-4"></div>
          <div className="hero-bg-slide-3"></div>
          <div className="hero-bg-slide-2">
            <div className="hero-ul-logo"></div>
          </div>
          <div className="hero-bg-slide-1"></div>
          <div className="skip-btn">
            <div
              data-menuanchor="cbyd"
              className="skip"
              onClick={() => skipHero()}
            >
              SKIP
            </div>
          </div>
          <div className="display-flex justify-center">
            <div className="down-arrow">
              <a onClick={() => nextSlide()}>
                <FontAwesomeIcon icon={faArrowDown} />
              </a>
            </div>
          </div>
        </div>
        <ReactFullpage
          ref={fpEl}
          // debug
          licenseKey={"CA747434-6C3640A7-81881045-30C01CBB"}
          paddingTop={"64px"}
          navigation
          menu={"#site-navbar"}
          anchors={anchors}
          sectionSelector={SECTION_SEL}
          sectionsColor={sectionsColor}
          scrollOverflow={true}
          autoScrolling={true}
          // normalScrollElements={'#main-content'}
          afterRender={() => {
            // TweenLite.set("#fp-nav", { opacity: "1" })
            // TweenLite.set(".skip-btn", { opacity: "1" })
            // TweenLite.set("#fullpage", { opacity: "1" })

            TweenLite.to("#fp-nav", 0.6, {
              autoAlpha: 1,
              ease: Expo.easeIn,
            })
            TweenLite.to(".skip-btn", 0.6, {
              autoAlpha: 1,
              ease: Expo.easeIn,
            })
            TweenLite.to("#fullpage", 0.6, {
              autoAlpha: 1,
              ease: Expo.easeIn,
            })
            TweenLite.to(".down-arrow", 0.6, {
              autoAlpha: 1,
              ease: Expo.easeIn,
            })
          }}
          onLeave={(origin, destination, direction) => {}}
          afterLoad={(section, origin, destination, direction) => {
            TweenLite.set(
              [
                ".hero-bg-slide-1",
                ".hero-bg-slide-2",
                ".hero-bg-slide-3",
                ".hero-bg-slide-4",
                ".hero-bg-slide-5",
                ".hero-bg-slide-6",
                ".hero-bg-slide-7",
              ],
              { display: "block" }
            )
            if (origin.anchor === "escape-plan") {
              TweenLite.to(".hero-bg-slide-1", 0.6, {
                autoAlpha: 1,
                ease: Expo.easeOut,
              })
              TweenLite.to(
                [
                  ".hero-bg-slide-2",
                  ".hero-bg-slide-3",
                  ".hero-bg-slide-4",
                  ".hero-bg-slide-5",
                  ".hero-bg-slide-6",
                ],
                0.6,
                { autoAlpha: 0, ease: Expo.easeOut }
              )
            } else if (origin.anchor === "close-before-you-doze") {
              TweenLite.to(".hero-bg-slide-2", 0.6, {
                autoAlpha: 1,
                ease: Expo.easeOut,
              })
              TweenLite.to(
                [
                  ".hero-bg-slide-1",
                  ".hero-bg-slide-3",
                  ".hero-bg-slide-4",
                  ".hero-bg-slide-5",
                  ".hero-bg-slide-6",
                ],
                0.6,
                { autoAlpha: 0, ease: Expo.easeOut }
              )
            } else if (origin.anchor === "fire-is-getting-faster") {
              TweenLite.to(".hero-bg-slide-3", 0.6, {
                autoAlpha: 1,
                ease: Expo.easeOut,
              })
              TweenLite.to(
                [
                  ".hero-bg-slide-1",
                  ".hero-bg-slide-2",
                  ".hero-bg-slide-4",
                  ".hero-bg-slide-5",
                  ".hero-bg-slide-6",
                ],
                0.6,
                { autoAlpha: 0, ease: Expo.easeOut }
              )
            } else if (origin.anchor === "make-a-900-difference") {
              TweenLite.to(".hero-bg-slide-4", 0.6, {
                autoAlpha: 1,
                ease: Expo.easeOut,
              })
              TweenLite.to(
                [
                  ".hero-bg-slide-1",
                  ".hero-bg-slide-2",
                  ".hero-bg-slide-3",
                  ".hero-bg-slide-5",
                  ".hero-bg-slide-6",
                ],
                0.6,
                { autoAlpha: 0, ease: Expo.easeOut }
              )
            } else if (origin.anchor === "take-it-down-a-notch") {
              TweenLite.to(".hero-bg-slide-5", 0.6, {
                autoAlpha: 1,
                ease: Expo.easeOut,
              })
              TweenLite.to(
                [
                  ".hero-bg-slide-1",
                  ".hero-bg-slide-2",
                  ".hero-bg-slide-3",
                  ".hero-bg-slide-4",
                  ".hero-bg-slide-6",
                ],
                0.6,
                { autoAlpha: 0, ease: Expo.easeOut }
              )
            } else if (origin.anchor === "good-morning-america") {
              TweenLite.to(".hero-bg-slide-6", 0.6, {
                autoAlpha: 1,
                ease: Expo.easeOut,
              })
              TweenLite.to(
                [
                  ".hero-bg-slide-1",
                  ".hero-bg-slide-2",
                  ".hero-bg-slide-3",
                  ".hero-bg-slide-4",
                  ".hero-bg-slide-5",
                ],
                0.6,
                { autoAlpha: 0, ease: Expo.easeOut }
              )
            }

            if (fpEl.current) {
              // TweenLite.set(".hero-content", { opacity: "1" })
              document
                .querySelectorAll(".slider")
                .forEach(item => item.classList.remove("hide"))

              fpEl.current.fullpageApi.reBuild()
            }
          }}
          render={({ state, fullpageApi }) => (
            <>
              <ReactFullpage.Wrapper>
                <div className="slider">
                  <div className="hero-content">
                    <div className="content">
                      <div className="max-width-slide-1">
                        <h2 className="title">
                          <FormattedMessage
                            id="plan-ahead-to-save-lives"
                            defaultMessage="Fire moves fast. Plan ahead to save lives."
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id="fire-moves-fast"
                            defaultMessage="Watch to see how quickly fire and toxic smoke can spread through your home leaving you with three minutes or less to escape. There won't be time to plan a way out in the moment. Don't wait, plan ahead. Use this How-To-Guide to start the fire safety conversation."
                          />
                        </p>
                        <Button
                          href={isEs ? "/es/#resources" : "/#resources"}
                          variant={"bg-white"}
                          icon={"arrow-down"}
                          className={"m-2"}
                          label={
                            <FormattedMessage
                              id="watch-video"
                              defaultMessage="Watch Videos"
                            />
                          }
                        />
                        <Button
                          href={
                            isEs
                              ? "https://www.dropbox.com/s/c68g90tw72r1zz4/UL-FSRI_220922_ES.pdf?dl=1"
                              : "https://www.dropbox.com/s/8akr8hk0rtkjzvb/UL-FSRI_220922.pdf?dl=1"
                          }
                          variant={"bg-white"}
                          icon={"arrow-down"}
                          label={
                            <FormattedMessage
                              id="download-guide"
                              defaultMessage="Download Guide"
                            />
                          }
                          className="m-2"
                        />
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="slider">
                  <div className="hero-content">
                    <div className="content">
                      <div className="max-width-slide-2">
                        <h2 className="title">
                          <FormattedMessage
                            id="close-before-you-doze"
                            defaultMessage="Close before you doze"
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id="event-of-a-home-fire"
                            defaultMessage="See the dramatic difference a closed door can make."
                          />
                        </p>
                        <Popup
                          show={modalShowSlide2}
                          onHide={() => modalPopUpSlide2(false)}
                          title={
                            "Close Before You Doze - See the Dramatic Difference a Door Can Make"
                          }
                          {...(isEs && { video: "daka4lDoHRY" })}
                          {...(!isEs && { vimeourl: "293952932" })}
                        />
                        <Button
                          href={"#fire-is-getting-faster"}
                          variant={"bg-white"}
                          icon={"arrow-right"}
                          label={
                            <FormattedMessage
                              id="watch-video"
                              defaultMessage="Watch the Video"
                            />
                          }
                          onClick={() => modalPopUpSlide2(true)}
                        />
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                {pageContext.language !== "es" && (
                  <div className="slider">
                    <div
                      className={isEs ? "mt-100 hero-content" : "hero-content"}
                    >
                      <div className="content">
                        <div className="max-width-slide-6">
                          <h2 className="title">
                            <FormattedMessage
                              id="designing-spaces"
                              defaultMessage="Designing Spaces airing on Lifetime TV covers fire safety tips and solutions from FSRI"
                            />
                          </h2>
                          <p>
                            <FormattedMessage
                              id="surviving-a-home-fire"
                              defaultMessage="Watch to learn more about the importance of planning ahead and steps you can take to increase your chances of surviving a home fire."
                            />
                          </p>
                          <Popup
                            show={modalShow}
                            onHide={() => modalPopUp(false)}
                            title={
                              "Contemporary Hazards in the Home: Fire Safety Tips and Solutions From the Experts"
                            }
                            vimeourl={"652162244"}
                          />
                          <Button
                            href={"#good-morning-america"}
                            variant={"bg-white"}
                            className={"btn-bg-white"}
                            icon={"play"}
                            label={
                              <FormattedMessage
                                id="play-video"
                                defaultMessage="Play Video"
                              />
                            }
                            onClick={() => modalPopUp(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="slider">
                  <div className="hero-content">
                    <div className="content">
                      <div className="max-width-slide-3">
                        <h2 className="title">
                          <FormattedMessage
                            id="fire-is-getting-faster"
                            defaultMessage="Fire is getting faster"
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id="spread-of-fire"
                            defaultMessage="Because of synthetic materials, furniture, and construction, fire spreads faster than ever before. Closing doors helps stop the spread of fire."
                          />
                        </p>
                      </div>
                      <div>
                        <img
                          className={"img-fluid img-slide img-slide-2"}
                          src={
                            isEs
                              ? require("./../vendor/creative/homepage/img-slide-2-es.png")
                              : require("./../vendor/creative/homepage/img-slide-2.png")
                          }
                          width={"100%"}
                          height={"auto"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider">
                  <div className="hero-content">
                    <div className="content">
                      <div className="max-width-slide-4">
                        <h2 className="title">
                          <FormattedMessage
                            id="900-degree-difference"
                            defaultMessage="Make a 900&deg; difference"
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id="the-event-of-a-fire"
                            defaultMessage="Research proves a closed door can mean the difference
                            between 1,000 degress and 100 degrees in the event of a
                            fire."
                          />
                        </p>
                      </div>
                      <div>
                        <img
                          className={"img-fluid img-slide img-slide-3"}
                          src={
                            isEs
                              ? require("./../vendor/creative/homepage/img-slide-3-es.png")
                              : require("./../vendor/creative/homepage/img-slide-3.png")
                          }
                          width={"100%"}
                          height={"auto"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider">
                  <div className="hero-content">
                    <div className="content">
                      <div className="max-width-slide-5">
                        <h2 className="title">
                          <FormattedMessage
                            id="take-it-down-a-notch"
                            defaultMessage="Take it down a notch"
                          />
                        </h2>
                        <p>
                          <FormattedMessage
                            id="carbon-monoxide-levels"
                            defaultMessage="During a fire, a closed door can keep carbon monoxide
                            levels at 1,000 PPM verses 10,000 PPM when a door is left
                            open."
                          />
                        </p>
                      </div>
                      <div className="slide-4-asset">
                        <img
                          className={"img-fluid img-slide img-slide-4-mobile"}
                          src={
                            isEs
                              ? require("./../vendor/creative/homepage/img-slide-4-es.png")
                              : require("./../vendor/creative/homepage/img-slide-4-mobile.png")
                          }
                          width={"100%"}
                          height={"auto"}
                        />
                        <img
                          className={"img-fluid img-slide img-slide-4"}
                          src={
                            isEs
                              ? require("./../vendor/creative/homepage/img-slide-4-es.png")
                              : require("./../vendor/creative/homepage/img-slide-4.png")
                          }
                          width={"100%"}
                          height={"auto"}
                        />
                      </div>
                      <div className="slide4-right-xl"></div>
                    </div>
                  </div>
                </div>

                <div className="slider">
                  <Container fluid>
                    <Container id={"main-content"}>
                      <Container className={"py-5 copy"}>
                        <Row
                          className={
                            "d-flex align-items-center home-top-content"
                          }
                          data-sal="fade"
                          data-sal-duration="800"
                          data-sal-easing="ease"
                        >
                          <Col
                            xs={12}
                            sm={6}
                            className={"mr-5 mb-sm-0 feature-img"}
                          >
                            <img
                              width="444px"
                              height="444px"
                              className={"img-fluid border-radius"}
                              src={require("../assets/images/scienceSafety.jpg")}
                            />
                          </Col>
                          <Col xs={12} sm={5} className={"body page-home"}>
                            <h2>
                              <FormattedMessage
                                id="the-science-of-safety"
                                defaultMessage="The science of safety"
                              />
                            </h2>
                            <p className={"p2"}>
                              <FormattedMessage
                                id="fsri"
                                defaultMessage="Close Before You Doze is brought to you by the Fire Safety Research Institute (FSRI), part of UL Research Institutes,
                            a group dedicated to increasing firefighter
                            knowledge to reduce injuries and deaths in the fire
                            service and in the communities they serve."
                              />
                            </p>
                            <p className={"p2"}>
                              <FormattedMessage
                                id="as-a-part-non-profit-organization"
                                defaultMessage="As part of UL’s nonprofit organization, FSRI
                            collaborates with the global fire service to
                            generate and share life-saving fire safety science
                            knowledge and develops cutting edge, practical
                            education aimed at helping firefighters and the
                            communities they serve stay safe by more effectively
                            protecting people and property."
                              />
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </Container>
                  </Container>
                </div>
                <div className="slider">
                  <Container fluid>
                    <Container id={"main-content-wrapper"}>
                      <Container className={"d-lg-none"}>
                        <Row>
                          <Col>
                            <img
                              className={"img-fluid"}
                              src={require("../assets/images/illustration_resources.png")}
                            />
                          </Col>
                        </Row>
                      </Container>
                      <Container
                        className={
                          "pb-5 px-5 my-5 section section-resource extended"
                        }
                        data-sal="fade"
                        data-sal-duration="800"
                        data-sal-easing="ease"
                      >
                        <Row>
                          <Col lg={4} className={"mt-5 mb-5 text-dark"}>
                            <h1>
                              <FormattedMessage
                                id="resources"
                                defaultMessage="Resources"
                              />
                            </h1>
                            <p>
                              <FormattedMessage
                                id="fire-safety-resources"
                                defaultMessage="Explore these fire safety resources to learn more
                            about how to help keep your family fire safe."
                              />
                            </p>
                            <Button
                              to={"/resources"}
                              label={
                                <FormattedMessage
                                  id="all-resources"
                                  defaultMessage="All Resources"
                                />
                              }
                              icon={"arrow-right"}
                              variant={"blue-outline"}
                              className={"btn-blue-outline"}
                            />
                          </Col>
                          <Col lg={1}></Col>
                          <Col className={"resources-illustration"}>
                            <img
                              className={"img-fluid"}
                              src={require("../assets/images/illustration_resources.png")}
                            />
                          </Col>
                        </Row>
                        <Row className={"resource-cards pt-5"}>
                          {resources.map(resource => (
                            <Col
                              key={resource.id}
                              xs={12}
                              sm={6}
                              md={6}
                              lg={4}
                              data-sal="slide-up"
                              data-sal-duration="800"
                              data-sal-delay="2000"
                              data-sal-easing="ease"
                              className={"mb-15"}
                            >
                              <ResourceCard className={"h-100"} {...resource} />
                            </Col>
                          ))}
                        </Row>
                      </Container>
                      <Container
                        className={"px-0 pt-2 section section-top text-dark"}
                      >
                        <Row>
                          <Col lg={4} md={6}>
                            <h1>
                              <FormattedMessage
                                id="are-you-a-first-responder"
                                defaultMessage="Are you a first responder?"
                              />
                            </h1>
                            <p>
                              <FormattedMessage
                                id="difference-a-closed-door-makes"
                                defaultMessage="Most people don't know the difference a closed door
                            makes in a fire. You can change that."
                              />
                            </p>
                            <Button
                              to={"/first-responders"}
                              label={
                                <FormattedMessage
                                  id="visit-the-first-responder-portal"
                                  defaultMessage="Visit the First Responder Portal"
                                />
                              }
                              icon={"arrow-right"}
                              variant={"blue-outline"}
                              className={"btn-blue-outline"}
                            />
                          </Col>
                          <Col className={"d-none d-sm-block d-lg-none"}>
                            <Col
                              className={
                                "d-flex justify-content-between align-items-center mb-4"
                              }
                            >
                              <IconSmokedetector />
                              <IconDoor />
                            </Col>
                            <Col
                              className={
                                "d-flex justify-content-between align-items-center"
                              }
                            >
                              <IconExtinguisher />
                              <IconSprinkler className={"mr-3"} />
                            </Col>
                          </Col>
                          <Col
                            lg={8}
                            className={
                              "d-none d-lg-flex justify-content-between align-items-center"
                            }
                            data-sal="slide-left"
                            data-sal-duration="800"
                            data-sal-delay="1500"
                            data-sal-easing="ease"
                          >
                            <IconExtinguisher />
                            <IconSprinkler />
                            <IconSmokedetector />
                            <IconDoor />
                          </Col>
                        </Row>
                      </Container>
                      <Container
                        className={"px-0 pt-4 section text-dark home-in-news"}
                      >
                        <h1>
                          <FormattedMessage
                            id="in-the-news"
                            defaultMessage="In the news"
                          />
                        </h1>
                        <p>
                          <FormattedMessage
                            id="closed-doors-are-helping-save-lives"
                            defaultMessage="Closed doors are helping save lives. Learn about
                            real-life incidents and see coverage of Close Before You
                            Doze in action."
                          />
                        </p>
                        <Button
                          to={"/in-the-news"}
                          label={
                            <FormattedMessage
                              id="all-news"
                              defaultMessage="All News"
                            />
                          }
                          icon={"arrow-right"}
                          variant={"blue-outline"}
                          className={"btn-blue-outline"}
                        />
                      </Container>
                      <Container>
                        <NewsCards items={newsDescendingOrder} />
                      </Container>
                    </Container>
                    <Footer />
                  </Container>
                </div>
              </ReactFullpage.Wrapper>
            </>
          )}
        />
      </Layout>
    </SimpleLocalize>
  )
}

export const HomepageQuery = graphql`
  query HomepageQuery {
    resourcesEnglish: allNodePublicResource(
      sort: { order: ASC, fields: [field_frontpage_order] }
      filter: { promote: { eq: true }, langcode: { eq: "en" } }
      limit: 6
    ) {
      nodes {
        id
        title
        field_vimeo_id
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        langcode
        promote
        order: field_frontpage_order
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    resourcesSpanish: allNodePublicResource(
      sort: { order: ASC, fields: [field_frontpage_order] }
      filter: { promote: { eq: true }, langcode: { eq: "es" } }
      limit: 6
    ) {
      nodes {
        id
        title
        field_vimeo_id
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        langcode
        promote
        order: field_frontpage_order
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    newsDescendingOrder: allNodeNews(
      sort: { order: DESC, fields: [created] }
      limit: 6
    ) {
      nodes {
        id
        title
        caption: field_caption
        source: field_source
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
